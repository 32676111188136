import Icon from '@/components/atoms/Icon/Icon';

/**
 * IQuote This is the interface for the Quote component.
 *
 * @interface
 */
export interface IQuote {
  /** Quote text */
  text: string;
  /**
   * Optional Classes for component
   *
   * @default ''
   */
  classes?: string;
}

/**
 * Quote
 *
 * A component to display a quote
 *
 * @param {IQuote} props - The props for the Quote component
 * @returns {React.FC<IQuote>} Quote Component
 */
const Quote: React.FC<IQuote> = ({ text, classes }) => {
  return (
    <div
      data-testid="quote"
      className={`flex flex-col items-center ${classes}`}
    >
      <Icon icon="quote" size={42} colorType="base" colorShade={300}></Icon>
      <span>
        <h2 className="font-amasis font-light line-clamp-4 mt-6 text-center text-h2">
          {text}
        </h2>
      </span>
    </div>
  );
};

Quote.defaultProps = {
  classes: '',
};
export default Quote;
