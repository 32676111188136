import {
  cubicBezier,
  motion,
  useAnimation,
  useInView,
  Variant,
} from 'framer-motion';
import { useEffect, useRef } from 'react';

/** AnimateItem Props */
export interface IAnimateItem {
  /** Children to animate */
  children: React.ReactNode;
  /** From variant */
  from?: Variant;
  /** To variant */
  to?: Variant;
  /** Delay */
  delay?: number;
  /** Duration */
  duration?: number;
  /** Classname */
  className?: string;
}

/**
 * AnimateItem
 *
 * @param {IAnimateItem} props - Props
 * @returns {AnimateItem} - AnimateItem
 */
const AnimateItem = (props: IAnimateItem) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      className={props.className}
      ref={ref}
      initial={'hidden'}
      animate={controls}
      variants={{
        hidden: {
          opacity: 0,
          ...props.from,
        },
        visible: {
          opacity: 1,
          scale: 1,
          translateX: 0,
          translateY: 0,
          transition: {
            delay: props.delay || 0.2,
            duration: props.duration || 0.9,
            ease: cubicBezier(0.17, 0.55, 0.55, 1),
          },
          ...props.to,
        },
      }}
      data-testid="animate-item"
    >
      {props.children}
    </motion.div>
  );
};

export default AnimateItem;
