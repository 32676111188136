import Paragraph from '@/components/atoms/Paragraph/Paragraph';

import Image, { ImageLoader } from 'next/image';
import Link from 'next/link';

/**
 * ICardArticle Interface for the Card Article Component
 *
 * @interface
 */
export interface ICardArticle {
  /** Title of the Article for the Card */
  title: string;
  /** The Excerpt of the Article to Display */
  excerpt: string;
  /** The Image of the Article to display */
  image: string;
  /**
   * The optional classes for the component
   *
   * @default ''
   */
  classes?: string;
  /**
   * The optional imageClasses for the component
   *
   * @default ''
   */
  imageClasses?: string;
  /** Url of the article to link to */
  url: string;
  /** The optional target for the url */
  target?: '_blank' | '_self' | '_parent' | '_top';
  /** The loader function for the image */
  loader?: ImageLoader;
}

/**
 * Card Article The Card Article Component that links to an url and displays an
 * image, title and excerpt
 *
 * @param {ICardArticle} props - The props for the Card Article Component
 * @returns {React.FC<ICardArticle>} Card Article Component
 */
const CardArticle: React.FC<ICardArticle> = ({
  title,
  excerpt,
  image,
  classes,
  url,
  imageClasses,
  target,
  loader,
}: ICardArticle) => {
  return (
    <div
      data-testid="card-article"
      className={`w-full h-[346px] sm:h-[268px] md:h-[436px] cursor-pointer [&:has(:focus-visible)]:ring-1 [&:has(:focus-visible)]:ring-focus-400 sm:rounded-[20px] md:rounded-3xl ${classes}`}
    >
      <Link href={url}>
        <a
          className="w-full h-[346px] sm:h-[268px] md:h-[436px] focus:ring-0 focus:ring-offset-0 focus-visible:ring-offset-0 focus-visible:ring-0 focus-visible:border-none focus-visible:outline-none"
          target={target}
        >
          <div
            className={`h-[218px] sm:h-[140px] md:h-72 relative rounded-2xl sm:rounded-[20px] md:rounded-3xl flex overflow-hidden ${imageClasses}`}
          >
            <Image
              data-testid="card-article-image"
              src={image}
              layout="fill"
              alt={`Image of ${title}`}
              objectFit="cover"
              className="scale-100 hover:scale-[102%] transition-transform duration-100"
              loader={loader}
              sizes="(max-width: 739px) 100vw, (max-width: 1199px) 33vw, 450px"
            />
          </div>
          <div className="w-full h-[124px] sm:h-28 md:h-[124px] mt-4 md:mt-6">
            <span className="font-bold font-petco text-h5 text-mkNavy-400 line-clamp-2">
              {title}
            </span>
            <Paragraph size="body4" className="mt-3 font-petco line-clamp-2">
              {excerpt}
            </Paragraph>
          </div>
        </a>
      </Link>
    </div>
  );
};

CardArticle.defaultProps = {
  classes: '',
  imageClasses: '',
  target: '_self',
};

export default CardArticle;
