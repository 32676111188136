import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import Image, { ImageProps } from 'next/image';

/**
 * ContentfulImage An image from the CDN
 *
 * @param {ImageProps} props - The props for the ContentfulImage component
 * @returns {React.FC<ImageProps>} Component
 */
const ContentfulImage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Image
      data-testid="contentful-image"
      alt="contentful image"
      {...props}
      loader={contentFulImageLoader}
    />
  );
};

export default ContentfulImage;
