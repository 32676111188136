import { ReactNode } from 'react';

/**
 * IPageWrapper
 *
 * @interface
 */
export interface IPageWrapper {
  /** The content of the page. */
  children: ReactNode;
  /**
   * The classes to apply to the component.
   *
   * @default ''
   */
  classes?: string;
}

/**
 * Page Wrapper Used to wrap the entire page in a fixed size container.
 *
 * @param {IPageWrapper} props - The props for the PageWrapper component
 * @returns {React.FC<IPageWrapper>} PageWrapper Component
 */
const PageWrapper: React.FC<IPageWrapper> = ({
  children,
  classes,
}: IPageWrapper) => {
  return <div className={`max-w-[1440px] mx-auto ${classes}`}>{children}</div>;
};

PageWrapper.defaultProps = {
  classes: '',
};

export default PageWrapper;
