// Today's date
export const today = new Date();
// Tomorrow's Date
export const tomorrow = new Date(today.getTime() + 86400000);

/**
 * Get a date object that is X minutes from now
 *
 * @param {number} minutes - Time in minutes
 * @returns {Date} A date object that is X minutes from now
 */
export const getDateXMinutesFromNow = (minutes: number) => {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
};
